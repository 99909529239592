(function(){
    var VideoIncrivel = document.querySelectorAll('[data-video="true"]');
    VideoIncrivel.forEach(function(el){
        // ESSE ELEMENTO EM CADA VEZ DO FOR
        var isso            = el;
        // PEGANDO O CONTEUDO DO ATRIBUTO SRC DO IFRAME
        var urlInteira      = el.querySelectorAll('[data-src-video] iframe')[0].getAttribute('src');
        // PEGANDO A PARTE DO EMBED EM DIANTE
        var srcVideo        = urlInteira.indexOf('/embed/');
        // PEGANDO ID DO VIDEO
        var urlInteiraVideo = urlInteira.substr(srcVideo).substr('7');
        // COLOCANDO URL PADRAO COM O ID DO VIDEO
        var distVideo       = 'https://www.youtube.com/embed/'+urlInteiraVideo+'?autoplay=1'
        el.addEventListener('click',function(){
            // UNICA PARTE EM JQUERY ;-;
            var HTMLTemplate = $(el).find('template').html();
            $(this).find('.card-midia__segura-video').attr('style','');
            $(this).find('.embed-responsive').html(HTMLTemplate);
            $(this).find('.video').attr('src',distVideo);
        });
    });
})();